import React from "react";
import Layout from "../components/Layout";
import { Link, graphql } from "gatsby";
import RedirectHeader from "../components/RedirectHeader";
import { parseNameAndSurname, parseNumberToHour } from "../utils/utils";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "../components/Button";
import { FaChevronDown } from "react-icons/fa";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import "./class.sass";
import CircleArrow from "../components/CircleArrow";
import PopupGallery from "../components/Gallery";
import { isMobile } from "react-device-detect";

const Course = ({ data }) => {
  const [showDropdowns, setShowDropdowns] = React.useState([false, false]);
  const { title, instructor, image, paidMaterials, eventDates, singlePrice, cyclicPrice, placeDirections, gallery, requiredMaterials } = data.markdownRemark.frontmatter
  const html = data.markdownRemark.html
  const contactPage = data.markdownRemark.fields.slug
  const [currPage, setCurrPage] = React.useState(1);
  const [showPopup, setShowPopup] = React.useState(false);
  const [currImageIndex, setCurrImageIndex] = React.useState(0);
  const pageSize = isMobile ? 2 : 3;
  const allMaterials = requiredMaterials && paidMaterials ? [...requiredMaterials, ...paidMaterials] : paidMaterials ? paidMaterials : requiredMaterials ? requiredMaterials : null


  const instructorData = data.allMarkdownRemark.edges.filter((edge) => (edge.node.frontmatter.name + " " + edge.node.frontmatter.surname) === parseNameAndSurname(instructor[0]))[0].node.frontmatter
  const instructorSlug = data.allMarkdownRemark.edges.filter((edge) => (edge.node.frontmatter.name + " " + edge.node.frontmatter.surname) === parseNameAndSurname(instructor[0]))[0].node.fields.slug
  const [galleryImages, setGalleryImages] = React.useState(gallery.slice(0, pageSize).map((image, index) => (
    {
      image: image.image.childImageSharp.gatsbyImageData,
      isActive: index === 0 ? true : false
    }
  )
  )
  )

  const toggleDropdown = (index) => {
    const newShowDropdowns = [...showDropdowns];
    newShowDropdowns[index] = !newShowDropdowns[index];
    setShowDropdowns(newShowDropdowns);
  }

  const showGalleryPopup = (index) => {
    setShowPopup(true);
    setCurrImageIndex(index + (currPage - 1) * pageSize);
  }


  const changeGalleryImage = (index) => {
    const newGalleryImages = [...galleryImages];
    newGalleryImages.forEach((image, i) => {
      if (i === index) {
        image.isActive = true;
      } else {
        image.isActive = false;
      }
    })
    setGalleryImages(newGalleryImages);
  }

  const changePage = (isNext) => {

    if (isNext) {

      if (currPage < Math.ceil(gallery.length / pageSize)) {
        setCurrPage(currPage + 1);
      }
    } else {
      if (currPage > 1) {
        setCurrPage(currPage - 1);
      }
    }
  }

  React.useEffect(() => {
    setGalleryImages(gallery.slice((currPage - 1) * pageSize, currPage * pageSize).map((image, index) => (
      {
        image: image.image.childImageSharp.gatsbyImageData,
        isActive: index === 0 ? true : false
      }
    )
    ))
  }, [currPage])






  return (
    <Layout>
      <div className="section-container">
        <section className="section">
          <RedirectHeader title={title} showRedirect={false} />
          <p>{`Zajęcia prowadzi ${parseNameAndSurname(instructor[0])}`}</p>
        </section>
        <GatsbyImage className="section-image " image={image.childImageSharp.gatsbyImageData} alt={title} />
      </div>
      <div className="section-container inner-html">
        {html && <section className="section" dangerouslySetInnerHTML={{ __html: html }} />}
        <section className="section">
          <Link to={`/contact${contactPage}`} className="button">
            <Button text="Skontaktuj się" />
          </Link>
        </section>
      </div>
      <div className="section-container section-alternative full-width">
        <section className="section">
          <div className="prices-info" style={{
            padding: "0 0 2rem 0"
          }}>
            <h5 className="h5-black" style={{
              fontSize: "2rem"
            }}>SZCZEGÓŁOWE INFORMACJE</h5>
            <div className="price-info-list">

              <div className={`price-info-item ${showDropdowns[0] ? "active" : ""}`} >
                <div className="price-info-item-header" onClick={() => toggleDropdown(0)}>
                  <p>Cennik i plan zajęć</p>
                  <FaChevronDown className="chevron-down" />
                </div>
                <table className="price-info-item-content">
                  <thead>
                    <tr>
                      <th>DZIEŃ</th>
                      <th>GODZINA</th>
                      <th>CENA</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventDates?.map((eventDate, index) => (
                      <tr key={index}>
                        <td>{eventDate.eventDay}</td>
                        <td>{parseNumberToHour(eventDate.start / 60) + " - " + parseNumberToHour(eventDate.start / 60 + eventDate.duration)}</td>
                        <td>{`${cyclicPrice} - ${singlePrice}`}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className={`price-info-item ${showDropdowns[1] ? "active" : ""}`} >
                <div className="price-info-item-header" onClick={() => toggleDropdown(1)}>
                  <p>Lista materiałów</p>
                  <FaChevronDown className="chevron-down" />
                </div>
                <ul className="price-info-item-content">
                  {allMaterials?.map((material, index) => (
                    <li key={index}>
                      {material.material}
                    </li>
                  ))}
                </ul>
              </div>

            </div>
          </div>
        </section>
      </div>
      <div className="section-container">
        <section className="section">
          <div className="instructor-header">
            <GatsbyImage className="instructor-image" image={instructorData.image.childImageSharp.gatsbyImageData} alt={instructorData.name} />
            <div className="instructor-header-text">
              <Link to={instructorSlug}><h5 to={instructorSlug} className="h5-black" style={{ textTransform: "uppercase" }}>{parseNameAndSurname(instructor[0])}</h5></Link>
              <p className="caption-light">{instructorData.profession}</p>
            </div>
          </div>
          <p className="bold-paragraph">{instructorData.about}</p>
          <p className="description">{instructorData.description}</p>
        </section>
      </div>
      <div className="section-container">
        <hr className="footer-hr" />
        <section className="section" style={{
          margin: "5rem 0"
        }}>
          <h5 style={{
            marginBottom: "2rem"
          }} className="h5-black">JAK DOTRZEĆ</h5>
          <ReactMarkdown>{placeDirections}</ReactMarkdown>
          <div style={{
            height: "3rem"
          }}></div>
          <Link to="/map" className="button">
            <Button text="Mapa" type="secondary" />
          </Link>
        </section>
      </div>
      <div className="section-container">
        <section className="section">
          <div className="gallery" >
            {
              showPopup ? <PopupGallery images={gallery} closePopup={() => setShowPopup(false)} currentImage={currImageIndex} /> : null
            }
            {galleryImages.map((image, index) => (
              <div key={index} className={`gallery-image ${image.isActive ? "active" : ""}`} onClick={() => showGalleryPopup(index)}>
                <GatsbyImage key={index} image={image.image} alt={title} />
              </div>
            ))}
          </div>
          <div className="gallery-pagination">
            <CircleArrow direction="left" onClick={() => changePage(false)} />
            <CircleArrow direction="right" onClick={() => changePage(true)} />
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Course;

export const pageQuery = graphql`
        query CourseByID($id: String!) {
          markdownRemark(id: {eq: $id }) {
            fields {
            slug
            }
          id
      html
        frontmatter {
          title
        singlePrice
        cyclicPrice
        placeDirections
        eventDates {
          duration
          eventDay
        start
        }
        gallery {
          image {
          childImageSharp {
          gatsbyImageData(quality: 100, height: 400)
            }
          }
        }
        paidMaterials {
          material
        }
        requiredMaterials {
          material
        }
        instructor
        image {
          childImageSharp {
          gatsbyImageData(quality: 100, height: 500)
          }
        }
      }
    }
        allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "instructor"}}}) {
          edges {
          node {
            fields {
            slug
            }
          frontmatter {
          name
          surname
          description
        profession
        about
        image {
          childImageSharp {
          gatsbyImageData(quality: 100, width: 200)
              }
            }
          }
        }
      }
    }
  }
        `
